
import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import { css } from "@emotion/core";
import BlockContent from "@sanity/block-content-to-react";

import { FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

import Layout from "../components/layout";

const AboutPage = ({ data }) => (
  <Layout title="about me" noBorder={true}>
    <section
      css={css`
        height: 100%;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
      `}
    >
      <div
        css={css`
          margin-bottom: 30px;
        `}
      >
        <BlockContent
          className="content"
          blocks={data.page.bio}
          serializers={{}}
        />
      </div>
      <div
        css={css`
          font-size: 1.5em;
          margin-bottom: 80px;
          color: #ff0000;
        `}
      >
        <a
          css={css`
            margin-right: 20px;
          `}
          href={data.page.instagram}
        >
          <FaInstagram />
        </a>
        <a
          css={css`
            margin-right: 20px;
          `}
          href={data.page.youtube}
        >
          <FaYoutube />
        </a>
        <a
          css={css`
            margin-right: 20px;
          `}
          href={data.page.twitter}
        >
          <FaTwitter />
        </a>
      </div>
    </section>
    <section
      css={css`
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: flex-end;
          align-content: center;
          margin-left: -46px;
        `}
      >
        {[0, 1, 2].map((i) => (
          <div
            key={i}
            css={css`
              position: absolute;
              margin-left: ${i * 23}px;
            `}
          >
            <Image
              fluid={data.image.childImageSharp.fluid}
              css={css`
                width: 350px;
                max-width: 75vw;
              `}
            />
          </div>
        ))}
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query AboutPageQuery {
    page: sanityAbout {
      bio: _rawBio
      twitter
      instagram
      youtube
    }
    image: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default AboutPage;
